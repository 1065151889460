/** @jsx jsx */
import { Styled, Flex, Box, jsx } from "theme-ui"
import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import ProductList from "../components/productList"
import ProductCard from "../components/productCard"

export default ({ data }) => {
  const {
    name,
    description,
    homepage,
    images,
    products,
  } = data.contentfulProducer

  return (
    <Layout>
      <SEO title={name} />
      <Styled.h1 sx={{ textAlign: "center", mt: 4, mb: [4, 5] }}>
        {name}
      </Styled.h1>
      {description && (
        <Box
          sx={{
            maxWidth: 900,
            fontFamily: "display",
            fontSize: [2, 3, 4],
            mx: "auto",
          }}
          dangerouslySetInnerHTML={{
            __html: description.childMarkdownRemark.html,
          }}
        />
      )}
      {homepage && (
        <Styled.a
          href={homepage}
          sx={{
            display: "block",
            maxWidth: 900,
            fontFamily: "display",
            mx: "auto",
            fontSize: [2, 3, 4],
          }}
        >
          {homepage}
        </Styled.a>
      )}
      {images && (
        <Flex
          sx={{
            width: "100%",
            mx: [-2],
            my: [4, 5],
            flexWrap: "wrap",
          }}
        >
          {images.map((image, key) => (
            <Img
              fluid={image.fluid}
              key={key}
              sx={{
                flex: ["none", null, image.fluid.aspectRatio],
                m: 2,
                width: ["100%", null, "auto"],
                minWidth: [288, 320],
              }}
            />
          ))}
        </Flex>
      )}
      <ProductList>
        {products &&
          products.map((product, id) => (
            <ProductCard product={product} key={id} />
          ))}
      </ProductList>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulProducer(slug: { eq: $slug }) {
      name
      slug
      description {
        childMarkdownRemark {
          excerpt(truncate: true)
          html
        }
      }
      homepage
      images {
        fluid {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      products {
        name
        slug
        externalLink
        producer {
          name
        }
        image {
          fluid(maxHeight: 1024, maxWidth: 512) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }
  }
`
