/** @jsx jsx */
import { jsx, Flex, Box, Styled } from "theme-ui"
import { Link } from "gatsby"
import Img from "gatsby-image"

export default ({ product }) => (
  <Flex
    as={Link}
    to={`/tuotteet/${product.slug}`}
    sx={{
      flexDirection: "column",
      alignItems: "center",
      width: ["100%", "50%", "33.3333%", "25%"],
      textAlign: "center",
      p: 3,
      mb: 4,
      color: "text",
      textDecoration: "none",
      ":hover": {
        color: "champagne",
      },
    }}
  >
    <Box sx={{ width: 128, height: 256, mb: 4 }}>
      <Img
        fluid={product.image.fluid}
        imgStyle={{ objectFit: "contain" }}
        sx={{ maxHeight: "100%" }}
      />
    </Box>
    <Styled.p
      sx={{
        mb: 1,
        textTransform: "uppercase",
        letterSpacing: "caps",
        fontSize: 1,
      }}
    >
      {product.producer.name}
    </Styled.p>
    <Styled.h3 sx={{ fontSize: 3, mt: 0, mb: 2, lineHeight: "heading" }}>
      {product.name}
    </Styled.h3>
    {product.externalLink && (
      <Box sx={{ fontSize: 1, color: "champagne" }}>Alkon tilausvalikoima</Box>
    )}
  </Flex>
)
